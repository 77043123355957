import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dynamic from "next/dynamic";
import React, { FC, useRef, useState } from "react";
import Image from "react-bootstrap/Image";

import { ImagePreviewModal } from "../../modals/ImagePreviewModal";
import { getYouTubeWatchUrl } from "../../utils/socialMediaUtils";
import styles from "./ContentCardRichBody.module.scss";

// import ReactPlayer from "react-player";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false }); // workaround for "Hydration failed because the initial UI does not match what was rendered on the server." - react-player@2.10.0

interface Props {
    content: {
        text: string;
        imageUrls?: string[];
        ytVideoId?: string;
    };
}

export const ContentCardRichBody: FC<Props> = ({ content }) => {
    const [previewIndex, setPreviewIndex] = useState(-1);
    const image = useRef<HTMLImageElement>();
    const [imgClass, setImgClass] = useState("");

    function handleThumbnailLoaded(imageElement: HTMLImageElement) {
        const { naturalWidth: w, naturalHeight: h } = imageElement;
        if (w / h > 1.7) {
            setImgClass(styles.imgWide);
        } else {
            setImgClass(styles.imgNarrow);
        }
    }

    return (
        <article className="mt-3">
            {content.ytVideoId && (
                <div className={styles["player-wrapper"]}>
                    <ReactPlayer
                        className={styles["react-player"]}
                        url={getYouTubeWatchUrl(content.ytVideoId)}
                        config={{ youtube: { playerVars: { controls: 1 } } }}
                        width="100%"
                        height="100%"
                        light
                        playing
                    />
                </div>
            )}

            {content.imageUrls?.length > 0 && (
                <>
                    {previewIndex >= 0 && (
                        <ImagePreviewModal index={previewIndex} hide={() => setPreviewIndex(-1)} urls={content.imageUrls} />
                    )}

                    <div onClick={() => setPreviewIndex(0)} className={`${styles["post-img-thumbnail"]} clickable`}>
                        <Image
                            ref={image}
                            src={content.imageUrls[0]}
                            fluid
                            className={`${imgClass} mx-auto d-block`}
                            loading="lazy"
                            alt="Image"
                            onLoad={(event) => handleThumbnailLoaded(event.currentTarget)}
                        />
                        {content.imageUrls.length > 1 && (
                            <div className={`${styles.more} text-center`}>
                                <span className={`${styles.moreBtn} rounded`}>
                                    <FontAwesomeIcon className="me-1" icon={faImage} /> {content.imageUrls.length}
                                </span>
                            </div>
                        )}
                    </div>
                </>
            )}

            <div className="mx-4 mt-3 text-multiline">{content.text}</div>
        </article>
    );
};
