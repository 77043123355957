import { format } from "date-fns";
import us from "date-fns/locale/en-US";

export function formatDateString(date: string | Date, dateFormat = "MMM d, yyyy - hh:mm a") {
    return format(parseDate(date), dateFormat, { locale: us });
}
export function parseDate(date: string | Date): Date {
    return typeof date === "string" ? new Date(date) : date;
}

export function utcToLocal(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export function toDateOnly(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
}
