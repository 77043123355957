import { FC, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";

interface Props {
    index: number;
    hide: () => void;
    urls: string[];
}

export const ImagePreviewModal: FC<Props> = (props) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        setIndex(props.index);
    }, [props.index]);

    function previous() {
        const length = props.urls.length;
        setIndex((index + length - 1) % length);
    }

    function next() {
        setIndex((index + 1) % props.urls.length);
    }

    if (index < 0) {
        return null;
    }

    return (
        <Lightbox
            mainSrc={props.urls[index] || ""}
            nextSrc={props.urls[(index + 1) % props.urls.length]}
            prevSrc={props.urls[(index + props.urls.length - 1) % props.urls.length]}
            onCloseRequest={props.hide}
            onMovePrevRequest={previous}
            onMoveNextRequest={next}
            reactModalStyle={{ overlay: { zIndex: 1020 } }}
        />
    );
};
