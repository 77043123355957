import getVideoId from "get-video-id";

const ytIdRegex = /^[a-zA-Z0-9_-]{11}$/;

export function isYouTubeId(yt: string) {
    return ytIdRegex.test(yt.trim());
}

export function isYouTubeUrlOrId(yt: string) {
    const trimmed = yt.trim();
    return (
        /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?$/.test(trimmed) || // eslint-disable-line no-irregular-whitespace
        ytIdRegex.test(trimmed)
    );
}

export function getYouTubeId(urlOrId: string): string {
    if (!urlOrId) {
        return "";
    }

    const result = getVideoId(urlOrId); // works only for full url
    if (result.service === "youtube") {
        return result.id;
    }
    if (!isYouTubeId(urlOrId)) {
        return "";
    }
    return urlOrId;
}

export function getYouTubeWatchUrl(id: string): string {
    return `https://www.youtube.com/watch?v=${id}`;
}

export function getYouTubeChannelUrl(id: string): string {
    if (!id) {
        return "";
    }
    return `https://www.youtube.com/channel/${id}`;
}

export function getFacebookProfileUrl(id: string): string {
    if (!id) {
        return "";
    }
    return `https://www.facebook.com/${id}`;
}

export function getTwitterProfileUrl(id: string): string {
    if (!id) {
        return "";
    }
    return `https://twitter.com/${id}`;
}

export function getInstagramProfileUrl(id: string): string {
    if (!id) {
        return "";
    }
    return `https://www.instagram.com/${id}`;
}

export function getYouTubeChannel(url: string): string {
    return getProfile(["youtube.com", "youtu.be"], url);
}

export function getFacebookProfile(url: string): string {
    return getProfile(["facebook.com"], url);
}

export function getTwitterProfile(url: string): string {
    return getProfile(["twitter.com"], url);
}

export function getInstagramProfile(url: string): string {
    return getProfile(["instagram.com"], url);
}

function getProfile(domains: string[], url: string): string {
    if (!url || url.trim().length === 0) {
        return null;
    }

    const split = url.split("/");
    const result = split[split.length - 1];
    if (!result) {
        return null;
    }

    const lowercase = result.toLowerCase();
    for (const domain of domains) {
        if (lowercase.includes(domain)) {
            return null;
        }
    }

    return result;
}
