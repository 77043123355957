import classNames from "classnames";
import { FC } from "react";
import Card from "react-bootstrap/Card";

import { BaseProps } from "../../models/props/baseProps";

interface Props extends BaseProps {
    children: React.ReactNode;
}

export const ContentCard: FC<Props> = ({ className, children }) => {
    return (
        <Card className={classNames("shadow-sm mb-4", className)}>
            <Card.Body className="p-0 pt-4">{children}</Card.Body>
        </Card>
    );
};
