import classNames from "classnames";
import { FC } from "react";

import { BaseProps } from "../models/props/baseProps";

interface Props extends BaseProps {
    count: number;
    limit: number;
}

export const TextLimitCounter: FC<Props> = (props) => {
    const displayCounter = () => props.count / props.limit > 0.9;

    return (
        <small className={classNames("text-secondary", props.className)} style={{ visibility: displayCounter() ? "visible" : "hidden" }}>
            {props.count}/{props.limit}
        </small>
    );
};
