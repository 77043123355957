import { FC } from "react";

import { CustomDateDto } from "../../models/dto/customDateDto";
import { formatDateString, utcToLocal } from "../../utils/dateUtils";
import { isNullOrUndefined } from "../../utils/utils";

const monthFormat = "MMM";
const dayFormat = "d";
const yearFormat = "yyyy";
const dateFormat = `${monthFormat} ${dayFormat}, ${yearFormat}`;
const dateTimeFormat = `${dateFormat} - hh:mm a`;

interface Props {
    utcDate: Date | CustomDateDto | string;
    onlyDate?: boolean;
}

export const DateTime: FC<Props> = ({ utcDate, onlyDate }) => {
    if (isNullOrUndefined(utcDate)) {
        throw new Error("Date is null or undefined.");
    }

    let date: Date;
    let format = onlyDate ? dateFormat : dateTimeFormat;
    if (utcDate instanceof Date) {
        date = utcDate;
    } else if (typeof utcDate === "string") {
        date = new Date(utcDate);
    } else {
        date = new Date(utcDate.year, utcDate.month === null ? 0 : utcDate.month - 1, utcDate.day || 1);
        format = "";
        if (utcDate.month) {
            format += monthFormat;
            if (utcDate.day) {
                format += " " + dayFormat;
            }
        }
        if (format) {
            format += ", ";
        }
        format += yearFormat;
    }

    date = utcToLocal(date);

    return <time dateTime={date.toISOString()}>{formatDateString(date, format)}</time>;
};
