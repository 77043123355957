import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";

import { AuthorDto } from "../../../users/models/dto/authorDto";
import { Author } from "../Author";
import styles from "./ContentCardHeader.module.scss";

interface Props {
    epochStartingYear: number;
    author: AuthorDto;
    publishedAt: string | Date;
    editedAt?: string | Date;
    snapshotDate?: string | Date;
    children?: React.ReactNode;
}

export const ContentCardHeader: FC<Props> = ({ epochStartingYear, author, publishedAt, editedAt, snapshotDate, children }) => {
    return (
        <div className="card-body-margin-x">
            <Row className={styles.font}>
                <Col>
                    <Author
                        epochStartingYear={epochStartingYear}
                        author={author}
                        publishedAt={publishedAt}
                        editedAt={editedAt}
                        snapshotDate={snapshotDate}
                    ></Author>
                </Col>
                {children && <Col>{children}</Col>}
            </Row>
        </div>
    );
};
