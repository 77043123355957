import Link from "next/link";
import React, { FC } from "react";

import { AppPath } from "../../appPath";
import { ContentCardHeader } from "../../main/components/contentCard/ContentCardHeader";
import { PostDto } from "../models/dto/postDto";
import { TopicView } from "../models/views/topicView";

interface Props {
    post: Pick<PostDto, "id" | "author" | "publishedAt" | "editedAt" | "snapshotDate" | "topic" | "epochStartingYear">;
    isPreview?: boolean;
}

export const PostHeader: FC<Props> = ({ post, isPreview }) => {
    return (
        <>
            <ContentCardHeader
                epochStartingYear={post.epochStartingYear}
                author={post.author}
                publishedAt={post.publishedAt}
                editedAt={post.editedAt}
                snapshotDate={post.snapshotDate}
            >
                {post.topic && (
                    <div className="text-end">
                        <div>
                            {!isPreview ? (
                                <Link href={AppPath.topic(post.topic)} passHref>
                                    <a>{post.topic.name}</a>
                                </Link>
                            ) : (
                                post.topic.name
                            )}
                        </div>
                        <small className="text-secondary">{TopicView.categoryName(post.topic)}</small>
                    </div>
                )}
            </ContentCardHeader>
        </>
    );
};
