import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { AppPath } from "../../appPath";
import { AuthorDto } from "../../users/models/dto/authorDto";
import { formatDateString } from "../utils/dateUtils";
import { Avatar } from "./Avatar";
import { DateTime } from "./date/DateTime";
import { TimeAgo } from "./date/TimeAgo";

interface Props {
    epochStartingYear: number;
    author: AuthorDto;
    publishedAt: string | Date;
    editedAt?: string | Date;
    snapshotDate?: string | Date;
}

export const Author: FC<Props> = ({ epochStartingYear, author, publishedAt, editedAt, snapshotDate }) => {
    return (
        <div className="d-flex flex-row">
            <Link href={AppPath.userProfile(epochStartingYear, author.username)} passHref>
                <a>
                    <Avatar className="clickable" src={author.avatarUrl} size="medium" username={author.username} />
                </a>
            </Link>

            <div className="d-flex flex-column ms-2">
                <Link href={AppPath.userProfile(epochStartingYear, author.username)} passHref>
                    <a className="fw-bold">{author.username}</a>
                </Link>

                <div className="text-secondary">
                    {snapshotDate && (
                        <OverlayTrigger
                            placement="auto"
                            delay={{ show: 150, hide: 400 }}
                            overlay={
                                <Tooltip id="date-tooltip">
                                    This is a snapshot from the past. Published at <DateTime utcDate={publishedAt} />
                                </Tooltip>
                            }
                        >
                            <span>
                                <DateTime utcDate={snapshotDate} onlyDate /> <FontAwesomeIcon className="ms-1" icon={faHistory} />
                            </span>
                        </OverlayTrigger>
                    )}
                    {!snapshotDate && <TimeAgo date={publishedAt} />}
                    {editedAt && (
                        <span title={formatDateString(editedAt)}>
                            <span className="bullet">·</span>Edited
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
