import { ParsedUrlQuery } from "querystring";

import axios, { AxiosInstance, CancelToken } from "axios";
import { GetServerSidePropsContext } from "next";

import { appendToFormData, getServerSideAxios } from "../../infrastructure/utils/axiosHelper";
import { ChangeCommentReactionDto } from "../models/dto/input/changeCommentReactionDto";
import { CreatePostCommentDto } from "../models/dto/input/createPostCommentDto";
import { CreatePostDto } from "../models/dto/input/createPostDto";
import { NostalgiaLikeReactionDto } from "../models/dto/nostalgiaLikeReactionDto";
import { PostCommentDto } from "../models/dto/postCommentDto";
import { PostDto } from "../models/dto/postDto";
import { TopicCategory } from "../models/enums/topicCategory";
import { PostsResult } from "../models/postsResult";

export class PostService {
    private _axios: AxiosInstance;

    constructor(axiosInstance?: AxiosInstance) {
        this._axios = axiosInstance || axios;
    }

    public static serverSide(context: GetServerSidePropsContext<ParsedUrlQuery>) {
        return new PostService(getServerSideAxios(context));
    }

    public async create(epochStartingYear: number, dto: CreatePostDto, images: File[]) {
        const formData = new FormData();
        appendToFormData(formData, dto);
        for (const image of images) {
            formData.append("images", image);
        }

        return await this._axios.post<PostDto>(`posts/${epochStartingYear}`, formData, { withCredentials: true });
    }

    public async getById(epochStartingYear: string | number, username: string, postId: string) {
        return await this._axios.get<PostDto>(`posts/${epochStartingYear}/@${username}/${postId}`, { withCredentials: true });
    }

    public async getUserPosts(epochStartingYear: string | number, username: string, lastPostId?: string, cancelToken?: CancelToken) {
        return await this._axios.get<PostsResult>(`posts/${epochStartingYear}/@${username}`, {
            params: { last: lastPostId },
            cancelToken,
            withCredentials: true,
        });
    }

    public async getPopularPosts(epochStartingYear: string | number, page?: string, cancelToken?: CancelToken) {
        return await this._axios.get<PostsResult>(`posts/${epochStartingYear}/popular`, {
            params: { page: page },
            cancelToken,
            withCredentials: true,
        });
    }

    public async getTopicPosts(
        epochStartingYear: string | number,
        category: TopicCategory,
        topicId: string,
        lastPostId?: string,
        cancelToken?: CancelToken
    ) {
        return await this._axios.get<PostsResult>(`posts/${epochStartingYear}/topics/${category}/${topicId}`, {
            params: { last: lastPostId },
            cancelToken,
            withCredentials: true,
        });
    }

    public async changeNostalgiaReaction(epochStartingYear: number, postId: string) {
        return await this._axios.put<{ value: boolean; count: number }>(`posts/${epochStartingYear}/${postId}/nostalgia`, null, {
            withCredentials: true,
        });
    }

    public async delete(epochStartingYear: number, postId: string) {
        return await this._axios.delete(`posts/${epochStartingYear}/${postId}`, { withCredentials: true });
    }

    public async createComment(epochStartingYear: number, postId: string, dto: CreatePostCommentDto) {
        return await this._axios.post<PostCommentDto>(`posts/${epochStartingYear}/${postId}/comments`, dto, { withCredentials: true });
    }

    public async deleteComment(epochStartingYear: number, postId: string, commentId: string) {
        return await this._axios.delete(`posts/${epochStartingYear}/${postId}/comments/${commentId}`, { withCredentials: true });
    }

    public async getComments(epochStartingYear: number, postId: string, lastCommentId?: string, preload?: boolean) {
        return await this._axios.get<PostCommentDto[]>(`posts/${epochStartingYear}/${postId}/comments`, {
            params: { last: lastCommentId, preload },
            withCredentials: true,
        });
    }

    public async changeCommentReaction(epochStartingYear: number, postId: string, commentId: string, dto: ChangeCommentReactionDto) {
        return await this._axios.put<NostalgiaLikeReactionDto>(`posts/${epochStartingYear}/${postId}/comments/${commentId}/reaction`, dto, {
            withCredentials: true,
        });
    }

    public async replyToComment(epochStartingYear: number, postId: string, commentId: string, dto: CreatePostCommentDto) {
        return await this._axios.post<PostCommentDto>(`posts/${epochStartingYear}/${postId}/comments/${commentId}/replies`, dto, {
            withCredentials: true,
        });
    }

    public async deleteCommentReply(epochStartingYear: number, postId: string, commentId: string, replyId: string) {
        return await this._axios.delete(`posts/${epochStartingYear}/${postId}/comments/${commentId}/replies/${replyId}`, {
            withCredentials: true,
        });
    }

    public async getCommentReplies(epochStartingYear: number, postId: string, commentId: string, lastReplyId?: string) {
        return await this._axios.get<PostCommentDto[]>(`posts/${epochStartingYear}/${postId}/comments/${commentId}/replies`, {
            params: { last: lastReplyId },
            withCredentials: true,
        });
    }

    public async changeCommentReplyReaction(
        epochStartingYear: number,
        postId: string,
        commentId: string,
        replyId: string,
        dto: ChangeCommentReactionDto
    ) {
        return await this._axios.put<NostalgiaLikeReactionDto>(
            `posts/${epochStartingYear}/${postId}/comments/${commentId}/replies/${replyId}/reaction`,
            dto,
            { withCredentials: true }
        );
    }
}

export const postService = new PostService();
