import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactTimeAgo from "react-timeago";

import { DateTime } from "./DateTime";

interface Props {
    date: string | Date;
}

export const TimeAgo: FC<Props> = ({ date }) => {
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id="button-tooltip">
                    <DateTime utcDate={date} />
                </Tooltip>
            }
        >
            <span className="cursor-default">
                <ReactTimeAgo minPeriod={60} maxPeriod={60} date={date} title={null} />
            </span>
        </OverlayTrigger>
    );
};
